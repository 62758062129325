/* eslint-disable react/jsx-pascal-case */
import { useState, useEffect } from "react";
import ItodoImage from '../../../elements/itodo-img';
import Dashboard_Drawer_Widget from "./dashboard_drawer_widget";
import { HiChartPie, HiCheckCircle, HiDotsCircleHorizontal, HiChevronDown, HiOutlineUser } from "react-icons/hi";
import { HiLogout } from "react-icons/hi";
import Overview from "../pages/overview";
import Ticket_Crea from "../pages/ticket_crea";
import Ticket_Gestione from "../pages/ticket_gestione";
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from "react-router-dom";

function DashBoard_Nav() {
    const [openDashboard, setOpenDashboard] = useState(false);
    const [openAccountIcon, setOpenAccountIcon] = useState(false);
    const [ticketNumber, setTicketsNumber] = useState(0);
    const [isComponentMounted, setIsComponentMounted] = useState(true);

    const [activeSection, setActiveSection] = useState("overview");
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAuthLoaded, setIsAuthLoaded] = useState(false);

    const renderActiveSection = () => {
        switch (activeSection) {
            case "overview":
                return <Overview />
            case "gestione-ticket":
                return <Ticket_Gestione />
            case "crea-ticket":
                return <Ticket_Crea />
            default:
                return null;
        }
    };

    const fetchTickets = async () => {
        try {
            const response = await fetch('https://www.tonight-torino.com/php/script.php');
            if (response.ok) {
                const data = await response.json();
                if (!isAdmin) {
                    const filteredData = data.filter(ticket => ticket.Autore === "sabelt@auth.com");
                    setTicketsNumber(filteredData.length);
                }
                else {
                    setTicketsNumber(data.length);
                }
            } else {
                console.error('Errore nella richiesta al server:', response.statusText);
            }
        } catch (error) {
            console.error('Errore nella richiesta al server:', error);
        }
    };


    useEffect(() => {
        if (isAuthLoaded) {
            fetchTickets();
        }
    }, [isAuthLoaded, isAdmin]);

    const auth = getAuth();
    const [userEmail, setUserEmail] = useState("");

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                const userEmail = user.email;
                setIsAdmin(userEmail.includes('admin@auth.com'));
                setUserEmail(userEmail);
            }
            else {
                setIsAdmin(false);
            }
            setIsAuthLoaded(true);
        });

        return () => unsubscribe();
    }, [auth]);



    const handleLogout = async () => {
        try {
            await signOut(auth);
            if (isComponentMounted) {
            }
            navigate("/login");
        } catch (error) {
            console.error("Errore durante il logout:", error.message);
        }
    };

    const handleExit = async () => {
        try {
            navigate("/");
        } catch (error) {
        }
    };

    return (
        <>
            <header className="dashboard_nav_header">
                <div className="dashboard_nav_intro">
                    <button onClick={() => setOpenDashboard(!openDashboard)} aria-label="Toggle Navigation" style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" stroke="currentColor" strokeWidth="2" d="M3 6h18M3 12h18M3 18h18" />
                        </svg>
                    </button>
                    <ItodoImage src="/images/logohead.png"></ItodoImage>
                    <div>Dashboard</div>
                </div>

                <div className="dashboard_nav_end_account">
                    <ItodoImage src={userEmail === "sabelt@auth.com" ? "/images/clienti/sabelt.png" : "/images/empty.png"}></ItodoImage>
                    <div className="dashboard_nav_end_account_email">
                        {userEmail}
                    </div>
                    <div className="dashboard_nav_end_account_icon" onClick={() => setOpenAccountIcon(!openAccountIcon)}>
                        <HiChevronDown></HiChevronDown>
                    </div>
                </div>
            </header>

            {/* Menu a tendina */}
            {openAccountIcon && (
                <div className="dashboard_dropdown_menu">
                    <div className="dashboard_dropdown_item" onClick={handleLogout}> <HiOutlineUser /> Logout</div>
                    <div className="dashboard_dropdown_item" onClick={handleExit}> <HiLogout /> Esci</div>
                </div>
            )}


            <div className={`dashboard_nav_drawer ${openDashboard ? 'open' : ''}`}>
                <Dashboard_Drawer_Widget Text="Overview" Icon={HiChartPie} onClick={() => setActiveSection("overview")} />
                <Dashboard_Drawer_Widget Text="Gestione Ticket" Icon={HiDotsCircleHorizontal} onClick={() => setActiveSection("gestione-ticket")} Number={ticketNumber} />
                <Dashboard_Drawer_Widget Text="Crea Ticket" Icon={HiCheckCircle} onClick={() => setActiveSection("crea-ticket")} />
                {/*<Dashboard_Drawer_Widget Text="Ticket attesi" Icon={HiInformationCircle} onClick={() => setActiveSection("ticket-completati")} Number="5"/>*/}
            </div>

            <div className="active-section" key={activeSection}>
                {renderActiveSection()}
            </div>
        </>
    );
}

export default DashBoard_Nav;
