import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from "axios";
import Selecter from "./selecter";
import HandleFile from "./handlefile";
import { getStorage, ref, listAll, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage";
import InfoViewer from "./info_viewer";

function TicketViewer({ data, handleChiudi, onTicketDelete, onTicketConcludi }) {

    console.log(data);

    const Titolo = data.Titolo;
    const decodedMessaggio = decodeURIComponent(data.Messaggio);
    const ID = data.ID;
    const Email = data.Email;
    const Data = data.Data;
    const Nome = data.Nome;
    const [Risposta, setRisposta] = useState("");

    const [isAdmin, setIsAdmin] = useState(false);
    const auth = getAuth();
    const [alreadyFiles, setAlreadyFiles] = useState([]);

    const [priorita, setPriorita] = useState(data.Priorita);
    const [tipoSWHW, setTipoSWHW] = useState(data.Tipo);

    const [editableTitolo, setEditableTitolo] = useState(Titolo);
    const [editableMessaggio, setEditableMessaggio] = useState(decodedMessaggio);
    const [lastMessaggio, setLastMessaggio] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [isAnswering, setIsAnswering] = useState(false);
    const [seeInfo, setSeeInfo] = useState(false);

    const [showSuccessPrendiMessage, setShowSuccessPrendiMessage] = useState(false);
    const [showErrorPrendiMessage, setShowErrorPrendiMessage] = useState(false);

    const handleSuccessPrendiMessage = () => {
        setShowSuccessPrendiMessage(true);
        setTimeout(() => setShowSuccessPrendiMessage(false), 2000); // Nasconde il messaggio di successo dopo 2 secondi
    };

    const handleErrorPrendiMessage = () => {
        setShowErrorPrendiMessage(true);
        setTimeout(() => setShowErrorPrendiMessage(false), 2000); // Nasconde il messaggio di errore dopo 2 secondi
    };

    useEffect(() => {
        if (auth.currentUser) {
            const userEmail = auth.currentUser.email;
            setIsAdmin(userEmail.includes('admin@auth.com'));
            console.log(auth.currentUser.email);
        }
        setRisposta(data.Risposta ? decodeURIComponent(data.Risposta) : null);
    }, [auth]);

    const handleDelete = async () => {

        const userConfirmed = window.confirm("Sei sicuro di voler eliminare questo ticket e tutti i file associati?");

        if (!userConfirmed) {
            return;
        }

        try {
            // Elimina il ticket dal server
            const response = await fetch(
                "https://www.tonight-torino.com/php/script.php",
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ticketId: ID }),
                }
            );

            if (response.ok) {
                // Callback per aggiornare l'elenco dei ticket
                onTicketDelete(ID);
                handleSuccessPrendiMessage();

                // Ora elimina i file associati al ticket su Firebase Storage
                const storage = getStorage();
                const folderRef = ref(storage, `ticket/${ID}/`);

                try {
                    // Elenca tutti i file nella cartella
                    const fileList = await listAll(folderRef);

                    // Elimina tutti i file uno per uno
                    const deletePromises = fileList.items.map((fileRef) => deleteObject(fileRef));
                    await Promise.all(deletePromises);

                    console.log(`Tutti i file associati al ticket ${ID} sono stati eliminati.`);
                } catch (storageError) {
                    console.error(`Errore nell'eliminazione dei file associati al ticket ${ID}:`, storageError);
                    handleErrorPrendiMessage();
                }
            } else {
                console.error("Errore nell'eliminazione del ticket:", response.statusText);
                handleErrorPrendiMessage();
            }

        } catch (error) {
            console.error("Errore nell'eliminazione del ticket:", error);
            handleErrorPrendiMessage();
        }
    };

    const handleConcludi = async () => {


        const userConfirmed = window.confirm("Sei sicuro di voler concludere questo ticket?");

        if (!userConfirmed) {
            return;
        }

        try {
            const response = await fetch(
                `https://www.tonight-torino.com/php/script.php`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ticketId: ID, stato: 2 }),
                }
            );

            if (response.ok) {
                onTicketConcludi(ID);
                handleSuccessPrendiMessage();
            } else {
                console.error(
                    "Errore nel cambiamento di stato del ticket:",
                    response.statusText
                );
                handleErrorPrendiMessage();
            }
        } catch (error) {
            console.error("Errore nel cambiamento di stato del ticket:", error);
            handleErrorPrendiMessage();
        }
        try {
            const custom = "Sc38ahD38sW31Hshue";

            const emailData2 = {
                to: Email, // Assuming Autore is the email address of the ticket author
                subject: "TICKET CONCLUSO",
                text: `Il tuo ticket aperto in data ${Data} di ${Nome}:\n \n ${decodedMessaggio}\n \n e' stato concluso da Scanteq srl, siamo lieti di aiutarvi!`,
            };

            console.log(emailData2);
            const response = await axios.post(
                "https://www.tonight-torino.com/php/send-email.php",
                emailData2,
                {
                    headers: {
                        "Custom-Header": custom,
                    },
                }
            );
            console.log("E-mail inviata con successo");
            console.log(response.data);
            handleSuccessPrendiMessage();
        } catch (error) {
            console.error("Errore nell'invio dell'e-mail:", error);
            handleErrorPrendiMessage();
        }
    };

    const handlePrendiInCarico = async () => {

        const userConfirmed = window.confirm("Sei sicuro di voler prendere in carico questo ticket?");

        if (!userConfirmed) {
            return;
        }

        try {
            const response = await fetch(
                `https://www.tonight-torino.com/php/script.php`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ticketId: ID, stato: 1 }),
                }
            );

            if (response.ok) {
                onTicketConcludi(ID);
                handleSuccessPrendiMessage();
            } else {
                console.error(
                    "Errore nel cambiamento di stato del ticket:",
                    response.statusText
                );
                handleErrorPrendiMessage();
                return;
            }
        } catch (error) {
            console.error("Errore nel cambiamento di stato del ticket:", error);
            handleErrorPrendiMessage();
            return;
        }


        try {
            console.log(Email);

            const emailData = {
                to: Email, // Assuming Autore is the email address of the ticket author
                subject: "TICKET PRESO IN CARICO",
                text: `Il tuo ticket aperto in data ${Data} di ${Nome}:\n \n ${decodedMessaggio}\n \n e' stato preso in carico da Scanteq srl`,
            };

            const custom = "Sc38ahD38sW31Hshue";

            console.log(emailData);
            const response = await axios.post(
                "https://www.tonight-torino.com/php/send-email.php",
                emailData,
                {
                    headers: {
                        "Custom-Header": custom,
                    },
                }
            );
            console.log("E-mail inviata con successo");
            onTicketConcludi();
            console.log(response.data);
            handleSuccessPrendiMessage();
        } catch (error) {
            handleErrorPrendiMessage();
            console.error("Errore nell'invio dell'e-mail:", error);
            // Puoi aggiungere il codice per gestire l'errore qui
        }
    };

    const handleIndietro = () => {
        setIsEditing(false);
    }

    const handleSave = async () => {

        const userConfirmed = window.confirm("Sei sicuro di voler modificare questo ticket?");

        if (!userConfirmed) {
            return;
        }

        try {
            const response = await fetch(
                `https://www.tonight-torino.com/php/script.php`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        ticketId: ID,
                        Titolo: editableTitolo,
                        Messaggio: encodeURIComponent(editableMessaggio),
                        Tipo: tipoSWHW,
                        Priorita: priorita === "Normale" ? 0 : priorita === "Importante" ? 1 : priorita === "Urgente" ? 2 : 0,
                    }),
                }
            );

            if (response.ok) {
                handleSuccessPrendiMessage();
                setIsEditing(false); // Disabilita la modalità di modifica
                onTicketConcludi();
            } else {
                console.error("Errore nel salvataggio del ticket:", response.statusText);
                handleErrorPrendiMessage();
            }
        } catch (error) {
            console.error("Errore nel salvataggio del ticket:", error);
            handleErrorPrendiMessage();
        }
    };

    const Choices1 = [
        {
            icon: '❗️',
            description: 'Urgente'
        },
        {
            icon: '⚠️',
            description: 'Importante'
        },
        {
            icon: '✅',
            description: 'Normale'
        }
    ];

    const Choices2 = [
        {
            icon: '💻',
            description: 'Software'
        },
        {
            icon: '🔧',
            description: 'Hardware'
        }
    ];


    const getFilesFromFirebase = async (ticketId) => {
        const storage = getStorage();
        const storageRef = ref(storage, `ticket/${ticketId}/`);

        try {
            const fileList = await listAll(storageRef);

            const filesData = await Promise.all(
                fileList.items.map(async (fileRef) => {
                    const url = await getDownloadURL(fileRef);
                    return { name: fileRef.name, url: url }; // Oggetto con nome e URL del file
                })
            );

            console.log(`File trovati per il ticket ${ticketId}:`, filesData);
            setAlreadyFiles(filesData); // Aggiorna lo stato con gli URL dei file
        } catch (error) {
            console.error(`Errore nel recupero dei file per il ticket ${ticketId}:`, error);
        }
    };

    useEffect(() => {
        getFilesFromFirebase(ID);
    }, [ID]);


    const handleCaricaFilesReturn = (files) => {
        setAlreadyFiles(files);
        console.log('Files caricati:', files);
    };

    const handleRispondi = async () => {

        const userConfirmed = window.confirm("Sei sicuro di voler rispondere a questo ticket?");

        if (!userConfirmed) {
            return;
        }

        const ID = data.ID;
        const messaggio = editableMessaggio;

        if (!ID || !messaggio) {
            console.error("ID o messaggio mancante.");
            return;
        }

        try {
            const response = await axios.put('https://www.tonight-torino.com/php/script.php', {
                ticketId: ID,
                Risposta: messaggio,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.status === 200) {

                uploadFilesToFirebase(alreadyFiles, ID);
                handleSuccessPrendiMessage();
                onTicketConcludi();
                setRisposta(messaggio);
                setIsAnswering(false);
            } else {
                console.error('Errore durante l\'invio della risposta:', response.statusText);
                handleErrorPrendiMessage();
            }
        } catch (error) {
            console.error('Errore nella richiesta PUT:', error);
            handleErrorPrendiMessage();
        }
    };

    const uploadFilesToFirebase = async (files, ticketId) => {
        const storage = getStorage();

        for (let file of files) {
            const storageRef = ref(storage, `ticket/${ticketId}/${file.name}`);

            try {
                // Prova a ottenere l'URL del file per verificare se esiste
                await getDownloadURL(storageRef);
                console.log(`Il file ${file.name} esiste già su Firebase, non verrà ricaricato.`);
            } catch (error) {
                // Se il file non esiste (errore 404), procedi al caricamento
                if (error.code === 'storage/object-not-found') {
                    try {
                        await uploadBytes(storageRef, file);
                        console.log(`File ${file.name} caricato con successo.`);
                    } catch (uploadError) {
                        console.error(`Errore nel caricamento del file ${file.name}:`, uploadError);
                    }
                } else {
                    console.error(`Errore nel controllo dell'esistenza del file ${file.name}:`, error);
                }
            }
        }
    };

    
    const handleApriRispondi = () => {
        setIsAnswering(true);
        setLastMessaggio(editableMessaggio);
        setEditableMessaggio(Risposta);
    }

    const handleIndietroRisposta = () => {
        setIsAnswering(false);
        setEditableMessaggio(lastMessaggio);
    }

    return (
        <>
            <div className="divApertoTicketMessaggio">
                <div className={`div-ticket-opened ${isEditing ? "modifying" : ""}`}>
                    <span onClick={handleChiudi} className="chiudiDivxTicket">
                        &times;
                    </span>

                    {/* Se è in modalità di modifica, mostra l'input, altrimenti mostra il titolo */}
                    {isEditing ? (
                        <input
                            className="div-ticket-opened-title width100"
                            type="text"
                            value={editableTitolo}
                            onChange={(e) => setEditableTitolo(e.target.value)}
                        />
                    ) : (
                        <div className="div-ticket-opened-title">{editableTitolo}</div>
                    )}

                    <div className="div-ticket-toolbar">
                        {isEditing ? (
                            <>
                                <Selecter Status="Priorità" Choices={Choices1} setSelection={setPriorita} AlreadySetted={priorita}></Selecter>
                                <Selecter Status="Tipo" Choices={Choices2} setSelection={setTipoSWHW} AlreadySetted={tipoSWHW}></Selecter>
                                <button className="div-ticket-toolbar-btnmodify" onClick={handleIndietro}>Indietro</button>
                                <button className="div-ticket-toolbar-btnmodify" onClick={handleSave}>Salva</button>

                            </>
                        ) : (
                            <>
                                {!isAnswering &&
                                    <>
                                        <button className="div-ticket-toolbar-btnmodify" onClick={() => setIsEditing(true)}>Modifica</button>
                                        <button className="div-ticket-toolbar-btnmodify" onClick={() => setSeeInfo(true)}>Vedi info</button>
                                        <HandleFile isAdmin={isAdmin} handleCaricaFilesReturn2={handleCaricaFilesReturn} ticketID={ID} title="Vedi allegati" VediAllegati={true} AlreadyFiles={alreadyFiles} />
                                    </>
                                }
                                {
                                    isAnswering &&
                                    <>
                                        <button className="div-ticket-toolbar-btnmodify" onClick={handleRispondi}>Salva</button>
                                        <button className="div-ticket-toolbar-btnmodify" onClick={handleIndietroRisposta}>Indietro</button>
                                        <HandleFile isAdmin={isAdmin} handleCaricaFilesReturn2={handleCaricaFilesReturn} ticketID={ID} title="Aggiungi allegati" AlreadyFiles={alreadyFiles} />                                    </>
                                }
                            </>
                        )}

                        {isAdmin && !isEditing && !isAnswering && (
                            <>
                                <button
                                    className="div-ticket-toolbar-btnmodify"
                                    onClick={handleDelete}
                                >
                                    Elimina
                                </button>
                                <button
                                    className="div-ticket-toolbar-btnmodify"
                                    onClick={handleConcludi}
                                >
                                    Concludi
                                </button>
                                <button
                                    className="div-ticket-toolbar-btnmodify"
                                    onClick={handlePrendiInCarico}
                                >
                                    Prendi in carico
                                </button>
                                <button
                                    className="div-ticket-toolbar-btnmodify"
                                    onClick={handleApriRispondi}
                                >
                                    Rispondi
                                </button>
                            </>
                        )}
                    </div>

                    {seeInfo &&
                        <>
                        <InfoViewer handleClose={() => setSeeInfo(false)} data={data}></InfoViewer>
                        </>
                    }

                    {/* Se è in modalità di modifica, mostra una textarea, altrimenti mostra il messaggio */}
                    {isEditing || isAnswering ? (
                        <textarea
                            className={`div-ticket-opened-message${Risposta != null ? "-admin" : ""}`}
                            value={editableMessaggio}
                            onChange={(e) => setEditableMessaggio(e.target.value)}
                        />
                    ) : (
                        <div className={`div-ticket-opened-message${Risposta != null ? "-admin" : ""}`}>
                            {editableMessaggio}
                        </div>
                    )}

                    {Risposta != null && !isAnswering &&
                        <>
                            <div className="div-ticket-response-title">Risposta:</div>
                            <div className={`div-ticket-opened-message-admin`}>
                                {Risposta}
                            </div>
                        </>
                    }
                </div>

                {showSuccessPrendiMessage && (
                    <div className={`success-message show`}>Successo!</div>
                )}
                {showErrorPrendiMessage && (
                    <div className={`error-message show`}>Errore!</div>
                )}
            </div>
        </>
    );

};

export default TicketViewer;