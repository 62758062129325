import DashBoard_Nav from "./dashboard_nav";

function DashBoard_Home() {
    return (
        <>
        <DashBoard_Nav></DashBoard_Nav>
        <div className="Main_DashBoard_Div">
           
        </div>
        </>
    );
};

export default DashBoard_Home;